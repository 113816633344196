.top-wrapp {
    padding-bottom: 10px;
}

.btnBiography {
    padding: 0px;
    height: 38px;
}

.pageHeader {
    padding: 30px;
}

.pageDetailsPadding {
    padding: 30px;
}

.pageAdvPadding {
    padding-top: 30px;
}

.navPadding {
    margin-left: 30px;
    padding-top: 20px;
}

.newsForCat {
    margin-top: -40px;
    padding-bottom: 20px;
}

.marginIcon {
    margin-top: -7px;
}
.p-Ema {
    padding: 10px;
    font-size: 16px;
}
.p-Ema-color {
    color: rgb(239, 102, 103);
}
.li-Ema {
    margin-left: 5%;
}
.buttom_Ema {
    margin-bottom: 10px;
    border: 0;
}
.btn-store {
    color: #777777;
    min-width: 254px;
    padding: 12px 20px !important;
    border-color: #dddddd !important;
}

.btn-store:focus,
.btn-store:hover {
    color: #ffffff !important;
    background-color: #168eea;
    border-color: #168eea !important;
}

.btn-store .btn-label,
.btn-store .btn-caption {
    display: block;
    text-align: left;
    line-height: 1;
}

.btn-store .btn-caption {
    font-size: 24px;
}
.box {
    position: relative;
    border-radius: 3px;
    /* background: #ffffff; */
    background: #f6f6f6;
    border-top: 3px solid lightblue;
    /* border-top: 3px solid lightblue; */
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(219, 184, 184, 0.3);
}

.searchField {
    width: 100%;
    border-radius: 5px;
    height: 38px;
    padding-left: 5px;
    border: 1px solid #cccccc;
    color: hsl(0, 0%, 20%);
}

.searchField:hover {
    border: 1px solid grey;
    cursor: default;
}

.searchField:focus {
    outline: none !important;
    border: 2px solid #2684ff;
    cursor: default;
    color: hsl(0, 0%, 20%);
}

.searchContent {
    text-align: right;
}

.thBorder {
    border-bottom: 1px solid lightblue;
}

.membersSearch {
    text-align: right;
    /* margin-top: -33px; */
}

.membersSelect {
    text-align: right;
    width: 150px;
}

.membersPading {
    padding-top: 10px;
    padding-bottom: 10px;
}

.membersBorder {
    border-bottom: 1px solid lightblue;
    border-top: 1px solid lightblue;
    cursor: pointer;
}

.hpParagraph {
    font-weight: bold;
    color: white;
    /* color: darkblue; */
}

.gradientIconStyle {
    background-color: #00dcf4;
}

.downloadDoc {
    background-color: #ef662f;
    font-weight: bold;
    border: 1px solid #ef662f;
}

.cyron {
    /* background-image:url(/public/img/gradient-final.png); */
    background-color: rgb(123, 155, 176);
    color: white;
    text-align: center;
    margin-bottom: 0xp;
    font-size: 16px;
    padding-top: 2px;
    border-top: 2px solid rgb(243, 243, 243);
}

.cyronStyle {
    margin-bottom: 0xp;
}

.election-row {
    position: relative;
    display: block;
    border-bottom: 1px solid #dcdcdc;
    margin: 0 0 20px 0;
    padding: 0 0 10px 80px;
}

.showPages:hover {
    cursor: pointer;
}

#heder-hidden {
    display: none;
    padding: 10px 15px 2px 15px;
    background-color: #fff;
    border-radius: 10px;
}

#heder-hidden li:first-child {
    border-top: none;
}
#heder-hidden li {
    border-top: 1px solid #c5c5c5;
}

#heder-hidden a {
    font-size: 10px;
}

.navbar-left {
    height: 112px;
}

.congress {
}

.congress:hover {
    cursor: pointer;
}
